// RankingList 作品排行榜
<template>
    <div class="view-ranking-list">
        <div class="ranking-list-content">
            <div class="ranking-list">
                <div class="ranking-title">作品排行榜</div>
                <div class="list-content">
                    <div class="works-item" v-for="(item, index) in dataList" :key="item.id">
                        <div class="ranking-num">
                            <img :src="require(`../static/image/rankingList/icon_top${index+1}.png`)"/>
                        </div>
                        <div class="works-image">
                            <img :src="item.image"/>
                        </div>
                        <div class="works-info">
                            <h4 class="works-title">{{item.title}}</h4>
                            <div class="agree-box">
                                <div class="agree-num">热度<span>{{ item.agreeNum }}</span></div>
                                <div class="agree-btn">
                                    <span :class="item.agree ? 'is-agree' : ''" @click="subAgree">{{ item.agree ? '已点赞' : '点赞' }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RankingList",
        data() {
            return {
                dataList: [
                    {
                        id: '123',
                        title: "俩受到法律接受了对方俩受到法律接受了对方",
                        time: '05：45',
                        author: '李大宝',
                        agreeNum: '2020',
                        agree: true,
                        image: require('../static/image/ny_bg.png')
                    }, {
                        id: '123',
                        title: "俩受到法律接受了对方",
                        time: '05：45',
                        author: '李大宝',
                        agreeNum: '2020',
                        agree: true,
                        image: require('../static/image/ny_bg.png')
                    },
                    {
                        id: '123',
                        title: "俩受到法律接受了对方",
                        time: '05：45',
                        author: '李大宝',
                        agreeNum: '2020',
                        agree: true,
                        image: require('../static/image/ny_bg.png')
                    },
                    {
                        id: '123',
                        title: "俩受到法律接受了对方",
                        time: '05：45',
                        author: '李大宝',
                        agreeNum: '2020',
                        agree: false,
                        image: require('../static/image/ny_bg.png')
                    }

                ]
            }
        },
        methods: {
            subAgree(){

            },
            getRankingList(){

            }
        },
        created() {
            this.getRankingList()
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .view-ranking-list {
        background: url("../static/image/ny_bg.png") no-repeat top left #be0b15;
        background-size: contain;
        padding-top: toRem(400);
        padding-bottom: toRem(40);
        .ranking-list-content {
            .ranking-list {
                background: #f9f2f3;
                margin: toRem(12);
                border-radius: toRem(12);
                padding: toRem(30) toRem(20);
                .ranking-title{
                    color:#A40E14;
                    text-align: center;
                    font-size: toRem(36);
                    background: url("../static/image/icon/bg_iconbt.png") no-repeat center center;
                    background-size: auto toRem(65);
                    margin-bottom: toRem(30);
                    font-weight: bold;
                }
                .list-content{
                    .works-item{
                        display: flex;
                        margin-bottom: toRem(30);
                        .ranking-num{
                            width: toRem(80);
                            margin-top: toRem(20);
                            img{
                                width: 100%;
                            }
                        }
                        .works-image{
                            width: toRem(130);
                            height: toRem(100);
                            border: 1px solid #ccc;
                            border-radius: toRem(6);
                            margin: 0 toRem(12) 0 0;
                            overflow: hidden;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .works-info{
                            .works-title{
                                width: toRem(350);
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                font-size: toRem(30);
                                font-weight: normal;
                                margin-bottom: toRem(10);
                            }
                            .agree-box{
                                display: flex;
                                .agree-num{
                                    flex: 1;
                                    color: #aaa;
                                    font-size: toRem(24);
                                    padding-top: toRem(10);
                                    span{
                                        margin-left: toRem(10);
                                        font-size: toRem(28);
                                        color: #eb3a19;
                                    }
                                }
                                .agree-btn{
                                   width: toRem(120);
                                    span{
                                        display: inline-block;
                                        width: toRem(120);
                                        height: toRem(46);
                                        line-height: toRem(46);
                                        border: 1px solid #eb3a19;
                                        text-align: center;
                                        border-radius: toRem(50);
                                        color: #eb3a19;
                                        font-size: toRem(24);
                                        &.is-agree{
                                            background: #eb3a19;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>